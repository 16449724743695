import { Observable } from "rxjs";
import { inject, Injectable } from "@angular/core";

import { AuthService } from "#services/shared";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn,
} from "@angular/router";
import { IUserRole } from "helpers/interfaces/user/user-role.interface";

export const IsApplicationUser: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const _authSvc = inject(AuthService);
  const _router = inject(Router);
  const roles: Array<number> = route?.children[0].data
    ? route?.children[0]?.data?.roles
    : route?.data?.roles;

  if (state.url && state.url.includes("worklist-item")) {
    _router.navigate(["/login"], {
      queryParams: { destinationUrl: state.url },
    });
  }

  if (_authSvc.isAuthorized() && !_authSvc.IsUserDisabled()) {
    if (_authSvc.isSystemAdmin()) {
      return true;
    }

    const userRoles: IUserRole[] = _authSvc.getRoles();
    if (Array.isArray(roles)) {
      const allowAccess: boolean = userRoles.some(
        (ur) => roles.indexOf(+ur.Role) > -1
      );
      if (allowAccess) {
        return allowAccess;
      }
    }
  }

  _router.navigate([
    _authSvc.isAuthenticated() ? "/error/not-authorized" : "/login",
  ]);

  return false;
};
